<template>
    <div>
        <v-row>
            <v-col class="d-none d-sm-flex">
                <v-toolbar-title>Firmen</v-toolbar-title>
            </v-col>
            <v-col>
                <v-text-field v-model="searchValue"
                              outlined
                              x-small
                              append-icon="mdi-magnify"
                              dense
                ></v-text-field>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.smAndUp" class="text-right">
                <v-btn @click="openCompanyForm(null)" color="primary">HINZUFÜGEN</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="tableItems">
                <v-data-table
                        :headers="headers"
                        :items="tableItems.data"
                        :server-items-length="tableItems.total"
                        :loading="loading"
                        class="elevation-1"
                        hide-default-footer
                >
                    <template v-slot:item.actions="{ item }">
                        <v-btn @click="openCompanyForm(item.id, true)" small icon>
                            <v-icon>
                                mdi-eye
                            </v-icon>
                        </v-btn>
                        <v-btn @click="openCompanyForm(item.id)" small icon color="success">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                        <v-btn @click="deleteCompany(item.id)" small icon color="accent">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Paginate from '@/mixins/paginate'
    import {companiesPaginated, removeCompany} from './../graphql/company'

    export default {
        mixins: [Paginate],
        data() {
            return {
                loading: false,
                fetchQuery: companiesPaginated,
                modelName: 'companies',
                headers: [
                    {
                        text: 'Id',
                        sortable: false,
                        value: 'id',
                    },
                    {
                        text: 'Name',
                        sortable: false,
                        value: 'name',
                    },
                    {
                        text: 'Firmennummer',
                        sortable: false,
                        value: 'company_number',
                    },
                    {
                        text: '',
                        sortable: false,
                        value: 'actions',
                        width: '120px'
                    }
                ],
            };
        },
        methods: {
            openCompanyForm(id, readonly = false) {
                this.$root.$children[0].$refs['company-form'].open(id, readonly, ()=>{this.$apollo.queries.tableItems.refetch()})
            },
            deleteCompany(id) {
                this.$root.$children[0].$refs.confirm.open('Delete', 'Sind Sie sicher das Sie entfernen möchten?', {color: 'accent'}).then((confirm) => {
                    if (confirm) {
                        this.$apollo.mutate({
                            mutation: removeCompany,
                            variables: {id},
                            update: (store, {data: {removeCompany}}) => {
                                this.tableItems.data = this.tableItems.data.filter(company => {
                                    return removeCompany.id != company.id
                                })
                            }
                        }).then(() => {
                            this.$root.$children[0].successMessage()
                        }).catch(()=>{
                            this.$root.$children[0].errorMessage()
                        })
                    }
                })
            },
        },
        mounted: function () {
            this.$root.$children[0].bottomMenu = [
                {
                    icon: 'add',
                    event: () => {
                        this.openCompanyForm(null)
                    }
                }
            ]
        },
    }
</script>