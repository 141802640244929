import gql from 'graphql-tag'

const pageSize = 25

export default {
    data() {
        return {
            page: 0,
            limit: pageSize,
            searchValue: '',
            tableItems: []
        }
    },
    methods: {
        loadMore(force = false) {
            let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
            let scrollShowing = document.documentElement.offsetHeight > window.innerHeight;

            if ((this.tableItems.has_more_pages && bottomOfWindow) || (this.tableItems.has_more_pages && !scrollShowing) || force) {
                if (force) {
                    this.page = 0
                } else {
                    this.page++
                }

                this.$apollo.queries.tableItems.fetchMore({
                    // New variables
                    variables: {
                        search: this.searchValue,
                        page: this.page,
                        limit: this.limit,
                    },
                    // Transform the previous result with new data
                    updateQuery: (previousResult, {fetchMoreResult}) => {
                        const newItems = fetchMoreResult[this.modelName].data
                        const oldItems = (this.page == 0 ? [] : previousResult[this.modelName].data)

                        let result = {}
                        result[this.modelName] = {
                            ...fetchMoreResult[this.modelName],
                            data: [
                                ...oldItems,
                                ...newItems
                            ]
                        }

                        return result
                    },
                }).then(() => {
                    if (this.tableItems.has_more_pages) {
                        this.loadMore()
                    }
                })
            } else {
                this.loading = false;
            }
        },
        onScroll() {
            window.onscroll = () => {
                this.loadMore()
            }
        },
    },
    apollo: {
        'tableItems': {
            query() {
                return this.fetchQuery
            },
            fetchPolicy: 'network-only',
            // Initial variables
            variables: {
                search: null,
                page: 0,
                limit: pageSize,
            },
            update: function (data) {
                return data[this.modelName]
            }
        },
    },
    mounted: function () {
        this.onScroll()
    },
    unmounted: function () {
        window.removeEventListener('onscroll', () => {

        });
    },
    watch: {
        'tableItems': function () {
            this.loadMore()
        },
        searchValue: function () {
            this.loadMore(true)
        },
    }
};